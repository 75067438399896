import React, { Dispatch } from 'react';
import { cloneDeep, isEmpty, keys, values } from 'lodash';
import { GridApi, ICellRendererParams, RowGroupOpenedEvent, RowNode } from 'ag-grid-community';

import { Button, Tooltip } from '@material-ui/core';
import { CheckCircleOutline, Lock } from '@material-ui/icons';

import DateField from '../DateField/DateField';

import {
  awards,
  awardsKey,
  expandedStatesData,
  GetLevelKeyValuePairs,
  initialExpandedStates,
  IRecalculate,
  SelectedStatesData,
  tabIndexStates,
  UserLocalStatesType,
  viewByStates,
  ViewLevelStatesData,
} from '../../types/IPreOrders';

import { errorMessages } from './Utils';
import { Alert } from '@material-ui/lab';
import { tooltipStyleMsg } from '../GridInput/GridInput';
import { dataFormatting } from '../../_lib/lib';
import loaderimage from '../LoadingDialog/loaderimage.svg';

export const fullyAwarded = (params: any) => {
  const { quantityFulfilled, awardedQuantity, quantityReadyToFulfill } = params.data;
  if (quantityFulfilled + quantityReadyToFulfill >= awardedQuantity) {
    return (
      <CheckCircleOutline data-id="fullyAwarded" style={{ marginLeft: '20px', color: 'green' }} />
    );
  }
  return null;
};

export const setDateEditable = (params: any) => {
  const { valueFormatted, disabled, updateHandler } = params;
  // const { availableFundConfirmedTimeStamp } = params.data;
  // if (availableFundConfirmedTimeStamp) {
  return (
    <DateField
      date={valueFormatted}
      disabled={disabled}
      params={params}
      setDate={() => {}}
      updateHandler={updateHandler}
      textfieldProps={{
        size: 'small',
      }}
      disableEdit={true}
      dateFormat="PP"
    />
  );
  // } else {
  //   return <>{valueFormatted}</>;
  // }
};

export const unAllocated = (params: any) => {
  if (params.value <= 0) return { backgroundColor: '#EDF6FF' };
  return { backgroundColor: 'rgb(255, 0, 0, 0.2)' };
};

export const recommendedCal = (data: awards, value: string, recommendedMsg: Dispatch<string>) => {
  const recommendedVal = (data.quantityRecommendedForFulfill ?? '') as string;
  if (parseInt(value) > parseInt(recommendedVal)) {
    recommendedMsg(recommendedVal);
  }
};

export const itemsBadge = (params: any) => {
  const tooltipClassMsg = tooltipStyleMsg();
  if (params.data.subGridByItem.length <= 1) return <></>;
  // const itemBadgeOverAllClass =
  //   params.viewByState === viewByStates.buyer ? 'itemBadgeCellClass' : '';
  return (
    <div>
      <Tooltip classes={tooltipClassMsg} title="Multiple buyers for this item">
        <div className="itemBadgeIcon">
          <span className="itemBadgeIconValue">{params.data.subGridByItem.length}</span>
        </div>
      </Tooltip>
    </div>
  );
};

export const Recalculate = (props: IRecalculate) => {
  const { disabled, onRecalculate } = props;
  return (
    <>
      <Button
        data-id="recalculateSelected"
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={onRecalculate}
        className="margin-bottom-2">
        Recalculate Selected
      </Button>
    </>
  );
};

export const LoadingComponent = () => (
  <div className="px-grid-loader">
    <img src={loaderimage} alt="loading"></img>
    {/* <h3 className="text-center margin-top-1">Loading...</h3> */}
  </div>
);

export const NoRowsMatchedComponent = () => (
  <div>
    <h5 className="text-center margin-top-1">No Matching Records Found</h5>
  </div>
);

export const getLevelKeyValuePairs = (viewByState: string, data: any): GetLevelKeyValuePairs => {
  const result: any = {};
  const viewStatus = viewByState === viewByStates.buyer ? true : false;
  result['topLevelKey'] = viewStatus ? 'buyerNumber' : 'itemElectId';
  result['secondLevelKey'] = viewStatus ? 'itemElectId' : 'buyerNumber';
  result['topLevelKeyValue'] = viewStatus ? data?.buyerNumber : data?.itemElectId;
  result['secondLevelKeyValue'] = viewStatus ? data?.itemElectId : data?.buyerNumber;
  return result;
};

export const checkStateFromMaster = (
  params: any,
  setUserLocalStates: Dispatch<any>,
  viewByState: string
) => {
  if (params.node.data?.checkingFromDetail ?? false) return;
  if (params.node.isSelected() === undefined) return;
  params.node.data.checkingFromMaster = true;
  const { topLevelKeyValue, secondLevelKey } = getLevelKeyValuePairs(viewByState, params.data);
  const childIdsSelected = params.node.isSelected()
    ? params.node.data.subGrid.map((item: awards) => item[secondLevelKey as awardsKey])
    : [];
  params.node.data.childIdsSelected = childIdsSelected;
  params.node.expanded && checkDetailRows(params.node, childIdsSelected, viewByState);
  if (params.node.isSelected()) {
    const valueToRecalculate: any = { [topLevelKeyValue]: childIdsSelected };
    setUserLocalStates((prev: any) => {
      return { ...prev, selectedStates: { ...prev.selectedStates, ...valueToRecalculate } };
    });
  } else {
    setUserLocalStates((prev: any) => {
      const currentData = cloneDeep(prev);
      delete currentData.selectedStates[topLevelKeyValue];
      return currentData;
    });
  }
  setTimeout(() => (params.node.data.checkingFromMaster = false), 5);
};

export const checkStateFromDetail = (
  node: any,
  idsSelected: string[],
  detailRowNode: RowNode,
  setUserLocalStates: Dispatch<any>,
  viewByState: string
) => {
  const { topLevelKeyValue, secondLevelKey, secondLevelKeyValue } = getLevelKeyValuePairs(
    viewByState,
    detailRowNode.data
  );
  if (node.data.checkingFromMaster ?? false) return;
  node.data.checkingFromDetail = true;
  const masterSelectedStatus =
    idsSelected.length === node.data.subGrid.length
      ? true
      : idsSelected.length === 0
      ? false
      : undefined;
  const childIdsSelected = new Set(node.data.childIdsSelected ?? []);
  detailRowNode.isSelected()
    ? childIdsSelected.add(secondLevelKeyValue)
    : childIdsSelected.delete(secondLevelKeyValue);
  node.data.childIdsSelected = [...childIdsSelected];
  node.selectThisNode(masterSelectedStatus);
  if (detailRowNode.isSelected()) {
    setUserLocalStates((prev: UserLocalStatesType) => {
      const currentParent = prev.selectedStates[topLevelKeyValue] ?? [];
      const currentData = new Set([...currentParent]);
      currentData.add(secondLevelKeyValue);
      return {
        ...prev,
        selectedStates: { ...prev.selectedStates, [topLevelKeyValue]: [...currentData] },
      };
    });
  } else {
    setUserLocalStates((prev: UserLocalStatesType) => {
      const currentTopLevelValue = prev.selectedStates[topLevelKeyValue] ?? [];
      if (currentTopLevelValue.length === 1) {
        const currentData = cloneDeep(prev);
        delete currentData.selectedStates[topLevelKeyValue];
        return currentData;
      }
      const currentData = new Set([...currentTopLevelValue]);
      currentData.delete(detailRowNode.data[secondLevelKey]);
      return {
        ...prev,
        selectedStates: { ...prev.selectedStates, [topLevelKeyValue]: [...currentData] },
      };
    });
  }
  setTimeout(() => (node.data.checkingFromDetail = false), 5);
};

export const checkDetailRows = (node: any, childIdsSelected: string[], viewByState: string) => {
  if (isEmpty(node?.data) || isEmpty(node)) return;
  node.data.childIdsSelected = childIdsSelected ?? [];
  node?.detailNode?.detailGridInfo?.api?.forEachNode((node: RowNode) => {
    const { secondLevelKeyValue } = getLevelKeyValuePairs(viewByState, node?.data ?? {});
    if (childIdsSelected?.includes(secondLevelKeyValue)) {
      node.setSelected(true);
    } else node.setSelected(false);
  });
};

export const rowOpenOrCloseMemorizer = (
  setUserLocalStates: Dispatch<any>,
  tabIndexState: tabIndexStates,
  viewByState: viewByStates,
  event: RowGroupOpenedEvent
) => {
  setUserLocalStates((latestUserLocalStates: any) => {
    const clonedUserLocalStates = cloneDeep(latestUserLocalStates as UserLocalStatesType);
    if (!clonedUserLocalStates.expandedStates)
      clonedUserLocalStates.expandedStates = initialExpandedStates;
    const currentExpandedStates = new Set(
      clonedUserLocalStates.expandedStates[tabIndexState][viewByState] ?? []
    ) as Set<string>;
    if (event.expanded) currentExpandedStates.add(event.node.id);
    else currentExpandedStates.delete(event.node.id);
    clonedUserLocalStates.expandedStates[tabIndexState][viewByState] = [...currentExpandedStates];
    return clonedUserLocalStates;
  });
};

export const remainingCellRules = (params: any) => {
  let redHightlight = false;
  if (
    (typeof params.value === 'string' && params.value.charAt(0) === '-') ||
    (typeof params.value === 'number' && params.value < 0)
  ) {
    redHightlight = true;
  }
  return redHightlight ? { color: 'red' } : { color: 'black' };
};

export const transformDataToRecalculate = (selectedStates: SelectedStatesData | undefined) => {
  if (!selectedStates) return;
  const transformedSelectedStates: SelectedStatesData = {};
  const selectedStatesKeys = keys(selectedStates);
  const selectedStatesValues = values(selectedStates);
  for (let index = 0; index < selectedStatesKeys.length; index++) {
    selectedStatesValues[index].forEach((secondLevelKey: string) => {
      const newSecondLevelKeys = new Set(transformedSelectedStates[secondLevelKey] ?? []);
      newSecondLevelKeys.add(selectedStatesKeys[index]);
      transformedSelectedStates[secondLevelKey] = [...newSecondLevelKeys];
    });
  }
  return transformedSelectedStates;
};

export const expandStatesPreservedFromLocal = (
  tabIndexState: tabIndexStates,
  viewByState: keyof ViewLevelStatesData,
  expandedStates?: expandedStatesData,
  gridApi?: GridApi
) => {
  if (!expandedStates || !gridApi || isEmpty(expandedStates) || isEmpty(gridApi)) return;
  const currentExpandedStates = expandedStates[tabIndexState][viewByState];
  currentExpandedStates.forEach((key: string) => gridApi?.getRowNode(key)?.setExpanded(true));
};

export const confirmDialogContent = (
  dataTocalculate: any,
  titleArray: string[],
  dialogFor: string,
  alertMessage: string,
  viewByState?: string,
  selectedCount?: number
) => {
  const arrayToRender: any = [];
  let selectedCounts: any;
  if (dialogFor === 'recalculate') {
    const [topLevel, secondLevel] =
      viewByState === viewByStates.buyer
        ? [viewByStates.buyer, viewByStates.item]
        : [viewByStates.item, viewByStates.buyer];
    selectedCounts = { buyer: [], item: [], total: 0 };
    keys(dataTocalculate.selectedStates).forEach((key: any) => {
      const topLevelSet = new Set(selectedCounts[topLevel]);
      const secondLevelSet = new Set(selectedCounts[secondLevel]);
      topLevelSet.add(key);
      dataTocalculate.selectedStates[key].forEach((value: any) => secondLevelSet.add(value));
      selectedCounts.total += dataTocalculate.selectedStates[key].length;
      selectedCounts[topLevel] = [...topLevelSet];
      selectedCounts[secondLevel] = [...secondLevelSet];
    });
  } else {
    selectedCounts = { totalOrders: selectedCount, totalQuantity: 0, totalPrice: 0 };
    dataTocalculate.forEach((element: any) => {
      selectedCounts.totalPrice += element.awardedPrice * element.quantityReadyToFulfill;
      selectedCounts.totalQuantity += element.quantityReadyToFulfill;
    });
  }
  keys(selectedCounts).forEach((key: any, index: any) => {
    let subobj = {};
    const value = [viewByStates.buyer, viewByStates.item].includes(key)
      ? selectedCounts[key].length
      : key === 'totalPrice'
      ? dataFormatting('currency', selectedCounts[key], true)
      : selectedCounts[key];
    subobj = {
      title: titleArray[index],
      value,
      dataID: key,
    };
    arrayToRender.push(subobj);
  });
  return (
    <>
      {arrayToRender.map((element: any) => {
        return (
          <div className="grid-x" key={element.dataID} style={{ width: '50%' }}>
            <h6 className="cell small-7">{element.title}:</h6>
            <h6 className="cell small-5">{element.value}</h6>
          </div>
        );
      })}
      <Alert style={{ marginTop: '20px' }} severity="info" variant="filled">
        {alertMessage}
      </Alert>
    </>
  );
};

export const checkValidTakeAll = (newValue: string | number, colId: string, data: awards) => {
  const {
    awardedQuantity,
    quantityFulfilled,
    quantityReadyToFulfill,
    takeAllLimitPercentage,
    isTakeAll,
  } = data;
  if (newValue === '')
    return {
      isValid: false,
      oldValue: isTakeAll ? 'Y' : 'N',
      message: errorMessages.updateAwards.takeAllBlankValidation,
    };
  let newTakeAllLimitPercentage = takeAllLimitPercentage;
  if (colId === 'isTakeAll')
    newTakeAllLimitPercentage = newValue === 'Y' ? takeAllLimitPercentage : 0;
  else newTakeAllLimitPercentage = +newValue;
  const newCalculatedAwarded =
    awardedQuantity + awardedQuantity * (newTakeAllLimitPercentage / 100);
  const endingFulfilled = quantityFulfilled + quantityReadyToFulfill;
  const isValid = newCalculatedAwarded >= endingFulfilled;
  return {
    isValid,
    oldValue: isTakeAll ? 'Y' : 'N',
    message: errorMessages.updateAwards.newFulfilledAwardedValidation,
  };
};

export const FundingNotConfirmedIndicator = () => (
  <div className="funding-notconfirmed-indicator">
    <Lock fontSize="large" />
    <h4 style={{ margin: '3px 0px 0px 3px' }}>
      Buyer Funding must be confirmed by finance before fulfillment can continue
    </h4>
  </div>
);

export const NotificationAlertDialogContent = (prefix: string) => {
  return <h6>{prefix} must be updated before allocating items.</h6>;
};

export const CurrencyToNum = (value: string) => {
  return parseInt(value.replace(/[^0-9.-]+/g, ''));
};

export const customSorting = (valueA: any, valueB: any) => {
  if (typeof valueA === 'number') {
    if (valueA === valueB) return 0;
    return valueA > valueB ? 1 : -1;
  }
  if (valueA.includes('$')) {
    if (CurrencyToNum(valueA) === CurrencyToNum(valueB)) return 0;
    return CurrencyToNum(valueA) > CurrencyToNum(valueB) ? 1 : -1;
  }
  if (valueA.match(/[a-z,-]/i)) {
    if (new Date(valueA) === new Date(valueB)) return 0;
    return new Date(valueA) > new Date(valueB) ? 1 : -1;
  }
  if (parseFloat(valueA) === parseFloat(valueB)) return 0;
  return parseFloat(valueA) > parseFloat(valueB) ? 1 : -1;
};

const appendRespectiveChildren = (eGui: HTMLElement, params: ICellRendererParams) => {
  if (!params.data[params.column.getColId()] || isEmpty(params.data[params.column.getColId()])) {
    eGui.append('--');
    return;
  }
  let childNode = '';
  const value = params.data[params.column.getColId()];

  if (params.column.getColId() === 'phonexItemDescription') {
    childNode = `<span>${value}</span>`;
  } else if (params.column.getColId() === 'orderNumbers') {
    const baseHylaUrl = 'https://hylauat.phonexinc.com/orders';
    value.forEach((orderNumber: any, index: number) => {
      childNode += `
        <span>
          <a class="po-grid-ordernumber" target="_blank" rel="noopener noreferrer" href="${baseHylaUrl}/${
        orderNumber.phonexOrderNumber
      }">
            ${
              orderNumber.referenceOrderNumber
                ? orderNumber.referenceOrderNumber
                : orderNumber.phonexOrderNumber
                ? orderNumber.phonexOrderNumber
                : '--'
            }
          </a>
            ${index !== value.length - 1 ? ', ' : ''}
        </span>`;
    });
  }
  eGui.innerHTML = childNode;
};

interface ICustomCellRendererBuyer {
  init?(params: ICellRendererParams): void;
  getGui(): HTMLElement;
  destroy?(): void;
  refresh(params: ICellRendererParams): boolean;
}

export class CustomCellRendererBuyer implements ICustomCellRendererBuyer {
  eGui: any;
  cellValue: any;
  content: any;

  init(params: ICellRendererParams) {
    this.eGui = document.createElement('div');
    appendRespectiveChildren(this.eGui, params);
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  refresh(params: any) {
    this.cellValue = this.getValueToDisplay(params);
    this.content.innerHTML = this.cellValue;
    return true;
  }

  destroy() {}

  getValueToDisplay(params: any) {
    if (params.hasOwnProperty('cellType')) {
      if (params.cellType === 'value') {
        return params.value;
      } else if (params.cellType === 'valueFormatted') {
        return params.valueFormatted;
      } else if (params.cellType === 'node.allChildrenCount') {
        return params.node.allChildrenCount;
      }
    }
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
